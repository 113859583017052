import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '@enterprise-ui/canvas-ui-react'
import constants from '../../utilities/constants'
import { useAppContext } from '../../Context'
import useServices from '../../services/useServices'
import AgGridCpm from '../../components/AgGridCpm'

const Locations = (props: any) => {
  const {
    setHeading,
    setLocations,
    locations,
    setAllProjectsForLocation,
    setSelectedLocation,
    setSelectedProgramName,
    setSelectedProgramType,
    setSelectedMilestone,
    setProjectSchedules,
    setSelectedScheduleViewType,
    setMilestoneActualOwners,
    setSelectedMilestoneOwner,
  } = useAppContext()
  const { getAllLocations } = useServices()
  const navigate = useNavigate()
  useEffect(() => {
    setHeading('Select Location')
    if (!locations) {
      ;(async function () {
        const result = await getAllLocations()
        setLocations(result)
        if (Array.isArray(result)) {
          setLocations(
            result.filter((location: any) => location.location_id !== null),
          )
        }
      })()
    }
    // reset projects for location if coming back to /locations path and reset the app header selected location value
    // also reset milestone values if coming here from milestones screen
    setAllProjectsForLocation(null)
    setSelectedLocation('')
    setSelectedProgramName(null)
    setSelectedProgramType(null)
    setSelectedMilestone(null)
    setProjectSchedules(null)
    setSelectedScheduleViewType(null)
    setMilestoneActualOwners(null)
    setSelectedMilestoneOwner(null)
  }, [])

  return (
    <AgGridCpm
      loading={!locations}
      data={locations}
      columns={[
        {
          field: 'value',
          headerName: 'Search and Click on Locations',
          flex: 1,
          sortable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          wrapText: true,
          autoHeight: true,
          unSortIcon: true,
          sort: 'asc',
          cellStyle: { wordBreak: 'normal', padding: 8 },
          cellClassRules: { 'no-border-cell': true },
          suppressHeaderFilterButton: true,
          suppressFloatingFilterButton: true,
          filterValueGetter: (props: any) =>
            `${props.data.location_id}${props.data.location_name}${props.data.city}${props.data.state}`, //filter all fields
          valueGetter: (props: any) => props.data.location_id, // for sorting - only need location id to sort asc or desc
          comparator: (data1: number, data2: number) => {
            return data1 - data2
          },
          cellRenderer: (props: any) => (
            <Card
              onClick={() => {
                navigate({
                  pathname: constants.PROJECT_PATH,
                  search: new URLSearchParams({
                    [constants.LOCATION_PARAM]: props.data.location_id,
                  }).toString(),
                })
                setSelectedLocation(
                  `${props.data.location_id} - ${props.data.location_name}`,
                )
              }}
              className="hc-pa-md clickable"
              elevation={0}
            >
              <div className="ag-grid-cell-card-container hc-pa-md">
                <div>
                  <div className="hc-fs-md card-content-div">{`${props.data.location_id} - ${props.data.location_name}`}</div>
                </div>
                <div>
                  <div className="hc-fs-md card-content-div">{`${
                    props.data.city
                  }${props.data.state ? ',' : null} ${props.data.state}`}</div>
                </div>
              </div>
            </Card>
          ),
        },
      ]}
    />
  )
}

export default Locations
