import React, { useEffect, useState, useRef } from 'react'
import { useAppContext } from '../../Context'
import { getUrlParam } from '../../utilities'
import constants from '../../utilities/constants'
import useServices from '../../services/useServices'
import {
  ProjectForLocationProps,
  iMilestone,
  iMilestoneAdditionalAttributes,
  iSchedule,
  iScheduleMilestone,
} from '../../types/types'
import {
  Button,
  Card,
  Form,
  Grid,
  Input,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import FileUpload from '../../components/FileUpload/FileUpload'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
moment.suppressDeprecationWarnings = true

const MilestoneDetails = () => {
  const {
    setHeading,
    setAllProgramTypes,
    allProgramTypes,
    setSelectedProgramName,
    setSelectedProgramType,
    selectedProgramName,
    selectedProgramType,
    setSelectedLocation,
    selectedLocation,
    setProjects,
    setAllProjectsForLocation,
    allScheduleViewTypes,
    setAllScheduleViewTypes,
    allMilestones,
    setAllMilestones,
    projectSchedules,
    setProjectSchedules,
    setSelectedMilestone,
    scheduleMilestoneToEdit,
    setScheduleMilestoneToEdit,
    setReload,
    scheduleMilestoneFiles,
    setScheduleMilestoneFiles,
    setSelectedScheduleViewType,
    reasonsForDelay,
    setReasonsForDelay,
    selectedReasonForDelay,
    setSelectedReasonForDelay,
    milestoneAttributes,
    setMilestoneAttributes,
  } = useAppContext()

  const {
    getProjectsForLocation,
    getProgramTypes,
    getSingleLocationDetails,
    getAllScheduleViewTypes,
    getAllMilestones,
    getScheduleMilestonesForProject,
    getAllReasonsForDelay,
    saveScheduleMilestone,
    uploadFiles,
    getAllMilestonesAdditionalAttributes,
  } = useServices()

  const navigate = useNavigate()
  const { milestone_id } = useParams()
  const milestoneId = milestone_id?.toString()
  let mileStoneToEditRef = useRef<any>(scheduleMilestoneToEdit)
  let prevMileStoneToEditValue = mileStoneToEditRef?.current
  const locationId = getUrlParam(constants.LOCATION_PARAM)
  const projectId = getUrlParam(constants.PROJECT_PARAM)
  const programTypeId = getUrlParam(constants.PROGRAM_TYPE_PARAM)
  const scheduleViewTypeIdInUrl = getUrlParam(
    constants.SCHEDULE_VIEW_TYPE_PARAM,
  )
  const getDisableDates = () => {
    return { before: moment().subtract(1, 'year').format('L') }
  }
  const getYearRange = () => {
    return {
      startYear: moment().subtract(1, 'year').year(),
      endYear: moment().add(10, 'year').year(),
    }
  }
  const getFormattedDate = (date: Date | string | null | undefined) => {
    return date !== null ? moment(date).format('L') : null
  }

  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const makeToast = useToaster()

  useEffect(() => {
    setHeading('Edit Milestone')

    //**Start API calls for Milestone edit page */
    if (!allScheduleViewTypes) {
      getAllScheduleViewTypes((result: any) => {
        setAllScheduleViewTypes(result)
        // Set active schedule view type so that the dropdown in milestones page is set, even after reload. We use the schedule_view_type_id param for this
        setSelectedScheduleViewType(
          result.find(
            (val: { value: string }) =>
              val.value.toString() === scheduleViewTypeIdInUrl,
          ),
        )
      })
    }

    if (!reasonsForDelay) {
      getAllReasonsForDelay((result: any) => {
        setReasonsForDelay(result)
      })
    }

    function milestonesForPrjctFn(allMilestonesFromApi?: iMilestone[]) {
      getScheduleMilestonesForProject(projectId, (result: iSchedule) => {
        // Add Milestone name property to the schedule milestone array
        if (result) {
          setProjectSchedules({
            ...result,
            schedule_milestones: result?.schedule_milestones?.map?.(
              (projectMilestones) => {
                return {
                  ...projectMilestones,
                  milestone_name: (
                    allMilestonesFromApi || allMilestones
                  )?.find?.(
                    (allMilestone) =>
                      projectMilestones.milestone_id ===
                      allMilestone.milestone_id,
                  )?.milestone_name,
                }
              },
            ),
          })
        } else {
          setProjectSchedules({}) // set to empty object so that the no schedules found doesnt show unnecessarily
        }
      })
    }

    //** End API calls for Milestone Edit page  */

    // Call all milestones API to get the Milestone name  - this is required for page reload
    if (!allMilestones) {
      getAllMilestones((allMilestones: iMilestone[]) => {
        setAllMilestones(allMilestones)
        if (projectId !== projectSchedules?.project_id?.toString()) {
          milestonesForPrjctFn(allMilestones)
        }
        setSelectedMilestone({
          id: milestoneId,
          name:
            allMilestones?.find(
              (milestone: iMilestone) =>
                milestone?.milestone_id?.toString() === milestoneId,
            )?.milestone_name ?? '',
        })
      })
    }

    // call API to get the location name to show in the header bar - this is required during reload
    if (!selectedLocation) {
      getSingleLocationDetails(locationId, (result: { location_name: any }) =>
        setSelectedLocation(`${locationId} ${result?.location_name}`),
      )
    }

    ;(async function () {
      // call program types only if not in context . No need to call when redirecting to the page all the time, unless its a reload
      let allProgramTypeResult = allProgramTypes || null
      if (!allProgramTypes) {
        allProgramTypeResult = await getProgramTypes()
        setAllProgramTypes(allProgramTypeResult)
      }
      // call API's to get the selected program name and selected program type - needs to be called after getting all program types above
      if (!selectedProgramType || !selectedProgramName) {
        getProjectsForLocation(locationId, (result: any[]) => {
          setAllProjectsForLocation(result)
          const selectedProgramType = allProgramTypeResult?.find(
            (programType) => programType?.value === parseInt(programTypeId),
          )

          const selectedProgramName =
            result?.find(
              (project: { project_id: number }) =>
                project?.project_id === parseInt(projectId),
            )?.program_name ?? ''

          setSelectedProgramName(selectedProgramName)
          setSelectedProgramType(selectedProgramType)
          setProjects(
            result?.filter?.(
              (val: ProjectForLocationProps) =>
                val.program_type_id === selectedProgramType?.value,
            ) ?? [],
          )
        })
      }
    })()
  }, [])

  useEffect(() => {
    function addFileRequiredPropertyToScheduleMilestoneToEdit(
      milestoneAttribs: iMilestoneAdditionalAttributes[],
    ) {
      const selectedScheduleMilestone =
        projectSchedules?.schedule_milestones.find(
          (scheduleMilestone: iScheduleMilestone) =>
            scheduleMilestone.milestone_id.toString() === milestoneId,
        )
      const fileRequired =
        milestoneAttribs?.find?.(
          (attrib: iMilestoneAdditionalAttributes) =>
            attrib.milestone_id.toString() === milestoneId,
        )?.file_required ?? false

      let milestoneToEdit = selectedScheduleMilestone
        ? {
            ...selectedScheduleMilestone,
            file_required: fileRequired,
          }
        : null

      setScheduleMilestoneToEdit(milestoneToEdit)
      mileStoneToEditRef.current = milestoneToEdit
      prevMileStoneToEditValue = mileStoneToEditRef?.current
    }

    if (!milestoneAttributes) {
      getAllMilestonesAdditionalAttributes(
        (milestoneAttribs: iMilestoneAdditionalAttributes[]) => {
          setMilestoneAttributes(milestoneAttribs)
          addFileRequiredPropertyToScheduleMilestoneToEdit(milestoneAttribs)
        },
      )
    } else {
      addFileRequiredPropertyToScheduleMilestoneToEdit(milestoneAttributes)
    }
  }, [projectSchedules])

  //******************Date Validations and Save Button Enable Validations***********//

  const forecastStartDateChanged =
    prevMileStoneToEditValue?.forecasted_start_date !==
    scheduleMilestoneToEdit?.forecasted_start_date
  const forecastEndDateChanged =
    prevMileStoneToEditValue?.forecasted_end_date !==
    scheduleMilestoneToEdit?.forecasted_end_date
  const actualStartDateChanged =
    prevMileStoneToEditValue?.actual_start_date !==
    scheduleMilestoneToEdit?.actual_start_date
  const actualEndDateChanged =
    prevMileStoneToEditValue?.actual_end_date !==
    scheduleMilestoneToEdit?.actual_end_date
  const notesChanged =
    prevMileStoneToEditValue?.notes !== scheduleMilestoneToEdit?.notes
  const filesChanged =
    prevMileStoneToEditValue?.schedule_milestone_toss_files.length !==
    prevMileStoneToEditValue?.schedule_milestone_toss_files.length +
      scheduleMilestoneFiles?.length
  const reasonForDelayChanged =
    prevMileStoneToEditValue?.reason_for_delay_id !==
    scheduleMilestoneToEdit?.reason_for_delay_id

  const forecastDateError =
    scheduleMilestoneToEdit?.forecasted_start_date! >
    scheduleMilestoneToEdit?.forecasted_end_date!
      ? true
      : false

  const actualDateError =
    scheduleMilestoneToEdit?.actual_start_date! >
    scheduleMilestoneToEdit?.actual_end_date!
      ? true
      : false
  const fileRequired = scheduleMilestoneToEdit?.file_required

  function reasonForDelayValidationErrorForForecastEndDate() {
    // If reason for delay is not empty, no validation required
    if (scheduleMilestoneToEdit?.reason_for_delay_id != null) {
      return false
    }
    if (
      scheduleMilestoneToEdit?.planned_end_date &&
      forecastEndDateChanged &&
      scheduleMilestoneToEdit?.forecasted_end_date! >
        scheduleMilestoneToEdit?.planned_end_date! &&
      selectedReasonForDelay === null
    ) {
      return true
    }
    if (
      !scheduleMilestoneToEdit?.planned_end_date &&
      forecastEndDateChanged &&
      selectedReasonForDelay === null
    ) {
      return true
    }
    return false
  }

  function reasonForDelayValidationErrorForActualEndDate() {
    // If reason for delay is not empty, no validation required
    if (scheduleMilestoneToEdit?.reason_for_delay_id != null) {
      return false
    }
    if (
      scheduleMilestoneToEdit?.planned_end_date &&
      actualEndDateChanged &&
      scheduleMilestoneToEdit?.actual_end_date! >
        scheduleMilestoneToEdit?.planned_end_date! &&
      selectedReasonForDelay === null
    ) {
      return true
    }
    if (
      !scheduleMilestoneToEdit?.planned_end_date &&
      actualEndDateChanged &&
      selectedReasonForDelay === null
    ) {
      return true
    }
    return false
  }

  function fileRequiredValidationErrorForActualEndDate() {
    // show file required message only if milestone file_required is true and actual end date is changed
    if (actualEndDateChanged && fileRequired) {
      if (
        // Check if files in required format already exists for the schedule milestone in toss
        scheduleMilestoneToEdit?.schedule_milestone_toss_files?.find?.(
          (currFile) => {
            return currFile?.schedule_milestone_toss_file_name?.includes?.(
              `${scheduleMilestoneToEdit?.milestone_name}_${projectId}_${locationId}_`,
            )
          },
        )
      ) {
        return false
      } else {
        if (!filesChanged) {
          return true
        }
        return false
      }
    }
    return false
  }

  function shouldSaveButtonBeDisabled() {
    // Validate reason for delay, file required and forecast/actual date compare and disable if any of them return true
    if (
      forecastDateError ||
      actualDateError ||
      reasonForDelayValidationErrorForForecastEndDate() ||
      reasonForDelayValidationErrorForActualEndDate() ||
      fileRequiredValidationErrorForActualEndDate()
    ) {
      return true
    }
    // Check if any fields have changed for Schedule Milestone and enable button .
    // Code will reach here only if the previous return did not run
    const fieldsChanged =
      forecastStartDateChanged ||
      forecastEndDateChanged ||
      actualStartDateChanged ||
      actualEndDateChanged ||
      notesChanged ||
      filesChanged ||
      reasonForDelayChanged

    if (fieldsChanged) {
      return false
    }
    return true
  }

  function reasonForDelayForecastEndDateHint() {
    if (
      scheduleMilestoneToEdit?.reason_for_delay_id != null &&
      forecastEndDateChanged
    ) {
      if (scheduleMilestoneToEdit?.planned_end_date !== null) {
        if (
          scheduleMilestoneToEdit?.forecasted_end_date! >
          scheduleMilestoneToEdit?.planned_end_date!
        ) {
          return 'Please update reason for delay if required'
        } else {
          return ''
        }
      } else {
        return 'Please update reason for delay if required'
      }
    } else {
      return ''
    }
  }
  function reasonForDelayActualEndDateHint() {
    const hintText = `Please update reason for delay ${
      fileRequired ? 'and/or File' : ''
    } if required`
    if (
      scheduleMilestoneToEdit?.reason_for_delay_id != null &&
      actualEndDateChanged
    ) {
      if (scheduleMilestoneToEdit?.planned_end_date !== null) {
        if (
          scheduleMilestoneToEdit?.actual_end_date! >
          scheduleMilestoneToEdit?.planned_end_date!
        ) {
          return hintText
        } else {
          return ''
        }
      } else {
        return hintText
      }
    } else {
      return ''
    }
  }

  function fileRequiredActualEndDateHint() {
    const requiredFileAlreadyExists =
      scheduleMilestoneToEdit?.schedule_milestone_toss_files?.find?.(
        (currFile) => {
          return currFile?.schedule_milestone_toss_file_name?.includes?.(
            `${scheduleMilestoneToEdit?.milestone_name}_${projectId}_${locationId}_`,
          )
        },
      )
    if (fileRequired && requiredFileAlreadyExists && actualEndDateChanged) {
      return 'Please add new files if required'
    } else {
      return ''
    }
  }

  //******************End Date Validations and Save Button Enable Validations***********//

  async function handleSaveButton() {
    setShowSpinner(true)
    try {
      if (scheduleMilestoneFiles.length > 0) {
        await uploadFiles(
          constants.SCHEDULE_MILESTONE,
          scheduleMilestoneToEdit?.schedule_milestone_id,
          scheduleMilestoneFiles,
          'throwIfError',
        )
      }
      // }
    } catch (error) {
      console.log('error uploading files', error)
      makeToast({
        type: 'error',
        message: `Error uploading files. Please try again`,
      })
      setShowSpinner(false)
      return
    }
    if (scheduleMilestoneToEdit) {
      try {
        await saveScheduleMilestone(scheduleMilestoneToEdit, 'throwIfError')
      } catch (error) {
        console.log('error saving Schedule Milestone', error)
        makeToast({
          type: 'error',
          message: `Error saving Schedule Milestone. Please try again.`,
        })
        setShowSpinner(false)
        return
      }
    }
    setShowSpinner(false)
    setScheduleMilestoneFiles([])
    setSelectedReasonForDelay(null)

    makeToast({
      type: 'success',
      message: 'Schedule Milestone saved successfully!',
    })

    setReload(true)

    navigate({
      pathname: constants.MILESTONES_PATH,
      search: new URLSearchParams({
        [constants.LOCATION_PARAM]: locationId ?? '',
        [constants.PROJECT_PARAM]: projectId ?? '',
        [constants.PROGRAM_TYPE_PARAM]: programTypeId ?? '',
      }).toString(),
    })
  }

  function getReasonsForDelay() {
    return reasonsForDelay ?? []
  }

  function handleCancelButton() {
    setReload(false)
    setScheduleMilestoneFiles([])
    setSelectedReasonForDelay(null)
    navigate({
      pathname: constants.MILESTONES_PATH,
      search: new URLSearchParams({
        [constants.LOCATION_PARAM]: locationId ?? '',
        [constants.PROJECT_PARAM]: projectId ?? '',
        [constants.PROGRAM_TYPE_PARAM]: programTypeId ?? '',
      }).toString(),
    })
  }

  function showReasonForDelay() {
    if (scheduleMilestoneToEdit?.planned_end_date) {
      if (
        scheduleMilestoneToEdit?.forecasted_end_date! >
          scheduleMilestoneToEdit?.planned_end_date! ||
        scheduleMilestoneToEdit?.actual_end_date! >
          scheduleMilestoneToEdit?.planned_end_date!
      ) {
        return true
      }
    }
    // If planned date is null, show reason for delay dropdown if either forecast end or actual end date is entered
    else {
      if (
        scheduleMilestoneToEdit?.forecasted_end_date ||
        scheduleMilestoneToEdit?.actual_end_date
      ) {
        return true
      }
      return false
    }
  }

  return (
    <>
      <Card className="cpm-milestone-pages-card">
        <div className="hc-pa-normal">
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="primary"
                disabled={shouldSaveButtonBeDisabled()}
                isLoading={showSpinner}
                onClick={handleSaveButton}
              >
                Save
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button type="secondary" onClick={handleCancelButton}>
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container justify="center" className="hc-pt-expanded">
            <Grid.Item xs={12}>
              <Form.Field
                id="planned_start"
                label="Planned Start"
                type="text"
                value={
                  scheduleMilestoneToEdit?.planned_start_date !== null &&
                  scheduleMilestoneToEdit?.planned_start_date !== ''
                    ? moment(
                        scheduleMilestoneToEdit?.planned_start_date,
                      ).format('MM/DD/YYYY')
                    : ''
                }
                disabled
                disableFieldInfo
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Form.Field
                id="planned_end"
                label="Planned End"
                type="text"
                value={
                  scheduleMilestoneToEdit?.planned_end_date !== null &&
                  scheduleMilestoneToEdit?.planned_end_date !== ''
                    ? moment(scheduleMilestoneToEdit?.planned_end_date).format(
                        'MM/DD/YYYY',
                      )
                    : ''
                }
                disabled
                disableFieldInfo
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <DatePicker
                id="forecast_start"
                label="Forecast Start"
                data-testid="forecast-start-datepicker"
                value={getFormattedDate(
                  scheduleMilestoneToEdit?.forecasted_start_date,
                )}
                onUpdate={(id, value) => {
                  setScheduleMilestoneToEdit(
                    scheduleMilestoneToEdit && {
                      ...scheduleMilestoneToEdit,
                      forecasted_start_date:
                        value !== null && value !== ''
                          ? moment(value).format('YYYY-MM-DD')
                          : null,
                    },
                  )
                }}
                highlight={
                  prevMileStoneToEditValue?.forecasted_start_date !==
                  scheduleMilestoneToEdit?.forecasted_start_date
                }
                placeholder={'MM/DD/YYYY'}
                disableDates={getDisableDates()}
                yearRange={getYearRange()}
                error={forecastDateError}
                errorText="Forecast start date should be less then Forecast end date"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <DatePicker
                id="forecast_end"
                label="Forecast End"
                value={getFormattedDate(
                  scheduleMilestoneToEdit?.forecasted_end_date,
                )}
                onUpdate={(id, value) => {
                  setScheduleMilestoneToEdit(
                    scheduleMilestoneToEdit && {
                      ...scheduleMilestoneToEdit,
                      forecasted_end_date:
                        value !== null && value !== ''
                          ? moment(value).format('YYYY-MM-DD')
                          : null,
                    },
                  )
                  value === '' && setSelectedReasonForDelay(null)
                }}
                highlight={
                  prevMileStoneToEditValue?.forecasted_end_date !==
                  scheduleMilestoneToEdit?.forecasted_end_date
                }
                placeholder={'MM/DD/YYYY'}
                disableDates={getDisableDates()}
                yearRange={getYearRange()}
                error={
                  forecastDateError ||
                  reasonForDelayValidationErrorForForecastEndDate()
                }
                errorText={`${
                  forecastDateError
                    ? 'Forecast start date should be less than Forecast end date. '
                    : ''
                }
                    ${
                      reasonForDelayValidationErrorForForecastEndDate()
                        ? 'Reason for delay is required if Forecast end date is modified'
                        : ''
                    }`}
                hintText={reasonForDelayForecastEndDateHint()}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <DatePicker
                id="actual_start"
                label="Actual Start"
                value={getFormattedDate(
                  scheduleMilestoneToEdit?.actual_start_date,
                )}
                onUpdate={(id, value) => {
                  setScheduleMilestoneToEdit(
                    scheduleMilestoneToEdit && {
                      ...scheduleMilestoneToEdit,
                      actual_start_date:
                        value !== null && value !== ''
                          ? moment(value).format('YYYY-MM-DD')
                          : null,
                    },
                  )
                }}
                highlight={
                  prevMileStoneToEditValue?.actual_start_date !==
                  scheduleMilestoneToEdit?.actual_start_date
                }
                placeholder={'MM/DD/YYYY'}
                disableDates={getDisableDates()}
                yearRange={getYearRange()}
                error={actualDateError}
                errorText="Actual start date should be less than Actual End date"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <DatePicker
                id="actual_end"
                label="Actual End"
                data-testid="actual-end-datepicker"
                value={getFormattedDate(
                  scheduleMilestoneToEdit?.actual_end_date,
                )}
                onUpdate={(id, value) => {
                  setScheduleMilestoneToEdit(
                    scheduleMilestoneToEdit && {
                      ...scheduleMilestoneToEdit,
                      actual_end_date:
                        value !== null && value !== ''
                          ? moment(value).format('YYYY-MM-DD')
                          : null,
                    },
                  )
                }}
                highlight={
                  prevMileStoneToEditValue?.actual_end_date !==
                  scheduleMilestoneToEdit?.actual_end_date
                }
                placeholder={'MM/DD/YYYY'}
                disableDates={getDisableDates()}
                yearRange={getYearRange()}
                error={
                  actualDateError ||
                  reasonForDelayValidationErrorForActualEndDate() ||
                  fileRequiredValidationErrorForActualEndDate()
                }
                errorText={
                  actualDateError
                    ? 'Actual start date should be less than Actual End date. '
                    : reasonForDelayValidationErrorForActualEndDate()
                      ? 'Reason for delay is required if Actual End date is modified.'
                      : fileRequiredValidationErrorForActualEndDate()
                        ? 'File needs to be uploaded if Actual End date is modified'
                        : ''
                }
                hintText={
                  reasonForDelayActualEndDateHint() ||
                  fileRequiredActualEndDateHint()
                }
              />
              {actualDateError &&
                reasonForDelayValidationErrorForActualEndDate() && (
                  <Input.Info error style={{ marginBottom: 4 }}>
                    Reason for delay is required if Actual End date is modified.
                  </Input.Info>
                )}
              {((reasonForDelayValidationErrorForActualEndDate() &&
                !actualDateError &&
                fileRequiredValidationErrorForActualEndDate()) ||
                (actualDateError &&
                  reasonForDelayValidationErrorForActualEndDate() &&
                  fileRequiredValidationErrorForActualEndDate())) && (
                <Input.Info error>
                  File needs to be uploaded if Actual End date is modified
                </Input.Info>
              )}
            </Grid.Item>
            {showReasonForDelay() && (
              <Grid.Item xs={12} className="hc-mt-xs">
                <Autocomplete
                  className="hc-mb-md"
                  id="reasonForDelay"
                  data-testid="reasonForDelayTestId"
                  required={scheduleMilestoneToEdit?.forecasted_end_date !== ''}
                  options={() => getReasonsForDelay()}
                  value={getReasonsForDelay().find(
                    (reason) =>
                      reason.id ===
                      scheduleMilestoneToEdit?.reason_for_delay_id,
                  )}
                  label={'Reason for Delay'}
                  highlight={
                    prevMileStoneToEditValue?.reason_for_delay_id !==
                    scheduleMilestoneToEdit?.reason_for_delay_id
                  }
                  onUpdate={(id, value) => {
                    setSelectedReasonForDelay(value)
                    setScheduleMilestoneToEdit(
                      scheduleMilestoneToEdit && {
                        ...scheduleMilestoneToEdit,
                        reason_for_delay_id:
                          value !== null && value !== '' ? value.id : null,
                      },
                    )
                  }}
                  disableFieldInfo
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12}>
              <Form.Field
                id="schedule_milestone_notes"
                label="Notes"
                type="textarea"
                data-testid="milestone-notes-textarea"
                value={scheduleMilestoneToEdit?.notes ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setScheduleMilestoneToEdit(
                    scheduleMilestoneToEdit && {
                      ...scheduleMilestoneToEdit,
                      notes: event.target.value,
                    },
                  )
                }}
                hintText={`${scheduleMilestoneToEdit?.notes?.length ?? 0}/1000`}
                maxLength="1000"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <FileUpload actualEndDateChanged={actualEndDateChanged} />
            </Grid.Item>
          </Grid.Container>
          {/*adding the action button at the bottom as well for better experience*/}
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="primary"
                disabled={shouldSaveButtonBeDisabled()}
                isLoading={showSpinner}
                onClick={handleSaveButton}
              >
                Save
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button type="secondary" onClick={handleCancelButton}>
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Card>
    </>
  )
}

export default MilestoneDetails
