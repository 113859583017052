import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Input } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import constants from '../../utilities/constants'
import { useAppContext } from '../../Context'
import useServices from '../../services/useServices'
import AgGridCpm from '../../components/AgGridCpm'
import { getUrlParam } from '../../utilities'
import { ProjectForLocationProps, iScheduleTemplate } from '../../types/types'
import SkeletonCpm from '../../components/SkeletonCpm'

const Projects = (props: any) => {
  const {
    isExternal,
    setHeading,
    setAllProgramTypes,
    allProgramTypes,
    allProjectsForLocation,
    setAllProjectsForLocation,
    allAssignedSources,
    setAllAssignedSources,
    allScheduleTemplates,
    setAllScheduleTemplates,
    setSelectedProgramName,
    setSelectedLocation,
    selectedLocation,
    selectedProgramType,
    setSelectedProgramType,
    setProjects,
    projects,
    setSelectedMilestone,
    setProjectSchedules,
    setSelectedScheduleViewType,
    setMilestoneActualOwners,
    setSelectedMilestoneOwner,
  } = useAppContext()

  const {
    checkIfTeamMember,
    getProjectsForLocation,
    getProgramTypes,
    getSingleLocationDetails,
    getAllScheduleTemplates,
  } = useServices()

  const navigate = useNavigate()
  const locationId = getUrlParam(constants.LOCATION_PARAM)

  useEffect(() => {
    setHeading('Select Project')
    // Call all projects for locationId - This needs to be called here to filter the program type Id's based on the unique of program type ids present in belwo API response
    if (!allProjectsForLocation) {
      let allProjectIds: number[] = []
      getProjectsForLocation(locationId, (result: any) => {
        setAllProjectsForLocation(result)
        if (isExternal) {
          if (result.length > 0) {
            result.map((project: any) => {
              return allProjectIds.push(project.project_id)
            })
          }
          checkIfTeamMember(allProjectIds, 'PROJECT', (result: any) => {
            setAllAssignedSources(result)
          })
        }
      })
    }
    // call program types only if not in context . No need to call when redirecting to the page all the time, unless its a reload
    if (!allProgramTypes) {
      getProgramTypes((result: any) => {
        setAllProgramTypes(result)
      })
    }
    // call schedule templates only if not in context. No need to call when redirecting to the page all the time, unless it's a reload
    if (!allScheduleTemplates) {
      getAllScheduleTemplates((allScheduleTemplates: iScheduleTemplate[]) => {
        setAllScheduleTemplates(allScheduleTemplates)
      })
    }

    // call API to get the location name to show in the header bar - this is required during reload
    if (!selectedLocation) {
      getSingleLocationDetails(locationId, (result: { location_name: any }) =>
        setSelectedLocation(`${locationId} ${result?.location_name}`),
      )
    }
    // reset values selected in Milestone selection screen
    setSelectedMilestone(null)
    setProjectSchedules(null)
    setSelectedScheduleViewType(null)
    setMilestoneActualOwners(null)
    setSelectedMilestoneOwner(null)
  }, [])

  // useEffect to filter the projects based on the selected program type from autocomplete
  useEffect(() => {
    if (selectedProgramType?.value && allProjectsForLocation) {
      setProjects(
        allProjectsForLocation?.filter?.(
          (val: ProjectForLocationProps) =>
            val.program_type_id === selectedProgramType?.value &&
            (isExternal
              ? allAssignedSources?.find(
                  (source) => source.source_id === val.project_id,
                )?.is_member
              : true),
        ) ?? [],
      )
    }
  }, [selectedProgramType])

  // function to get the unique program types from all projects for the location
  function getUniqueProgramTypesForSelectedLocationProjects() {
    const uniqueProgramTypesFromProjects = [
      ...new Set(
        allProjectsForLocation?.map?.(
          (project: ProjectForLocationProps) => project.program_type_id,
        ) ?? [],
      ),
    ]
    return (
      allProgramTypes?.filter?.((val) =>
        uniqueProgramTypesFromProjects.includes(val.value),
      ) ?? []
    )
  }

  return (
    <>
      {allProgramTypes && allProjectsForLocation ? (
        <Autocomplete
          className="hc-mb-md"
          id="programTypes"
          required
          options={() => getUniqueProgramTypesForSelectedLocationProjects()}
          value={selectedProgramType}
          label={'Select a Program Type'}
          onUpdate={(id, value) => {
            setSelectedProgramType(value)
          }}
          disableFieldInfo
        />
      ) : (
        <SkeletonCpm highlightColor="white" height={30} />
      )}
      {selectedProgramType?.value ? (
        <AgGridCpm
          data={projects}
          loading={!projects}
          columns={[
            {
              field: 'value',
              headerName: 'Search and Click on Project',
              flex: 1,
              sortable: true,
              filter: 'agTextColumnFilter',
              suppressHeaderFilterButton: true,
              suppressFloatingFilterButton: true,
              floatingFilter: true,
              suppressMenu: true,
              wrapText: true,
              autoHeight: true,
              unSortIcon: true,
              sort: 'asc',
              cellStyle: { wordBreak: 'normal', padding: 8 },
              cellClassRules: { 'no-border-cell': true },
              floatingFilterComponentParams: {
                suppressFilterButton: true,
              },
              filterValueGetter: (props: any) =>
                `${props.data.project_id}${props.data.program_name}`, //filter all fields
              valueGetter: (props: any) => props.data.project_id, // for sorting - only need location id to sort asc or desc
              comparator: (data1: number, data2: number) => {
                return data1 - data2
              },
              cellRenderer: (props: any) => (
                <Card
                  onClick={() => {
                    navigate({
                      pathname: constants.MILESTONES_PATH,
                      search: new URLSearchParams({
                        [constants.LOCATION_PARAM]: locationId,
                        [constants.PROJECT_PARAM]: props.data.project_id,
                        [constants.PROGRAM_TYPE_PARAM]:
                          selectedProgramType?.value,
                      }).toString(),
                    })
                    setSelectedProgramName(props.data.program_name)
                  }}
                  className="hc-pa-md clickable"
                  elevation={0}
                >
                  <div className="ag-grid-cell-card-container hc-pa-md">
                    <div>
                      <Input.Label className="hc-fs-md">
                        Program Name
                      </Input.Label>
                      <div className="hc-fs-md card-content-div">
                        <strong>{props.data.program_name}</strong>
                      </div>
                    </div>
                    <div>
                      <Input.Label className="hc-fs-md">Project ID</Input.Label>
                      <div className="hc-fs-md card-content-div">
                        <strong>{props.data.project_id}</strong>
                      </div>
                    </div>
                  </div>
                </Card>
              ),
            },
          ]}
        />
      ) : null}
    </>
  )
}

export default Projects
